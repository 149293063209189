import React from "react";
import axios from "axios";

import { useToast } from "@/hooks/useToast";
import { useApi } from "@/hooks/useApi";

import { LayoutContext } from "@/contexts/LayoutContext";
import { INITIAL_QR_DATA, QREditorContext } from "@/contexts/QREditorContext";
import { UserContext, USER_INITIAL_DATA } from "@/contexts/UserContext";

import postLoginPostHandler from "@/interface/api/postLogin";
import getProfileHandler from "@/interface/api/getProfile";

import { userProfileApiToDataTransformer } from "@/services/UserService";
import { amplitudeTrackUser } from "@/services/AmplitudeService/AmplitudeService";
import {
  getAuthCookie,
  removeAuthCookie,
  removeCookie,
  setAuthCookie,
} from "@/services/CookiesService";

import { PostLoginResponseData } from "@/interface/api/postLogin/types";
import { QR_REFERENCE_COOKIE } from "@/containers/AuthContainer/types";
import { GetProfileResponse } from "@/interface/api/getProfile/types";
import { UserContextProps } from "@/contexts/UserContext/types";
import { UTMS } from "@/components/StoreUtms/types";

const onHandleLogin = ({ email, password, request, reference }) => {
  request({
    email,
    password,
    reference,
  }).finally(() => {
    removeCookie(QR_REFERENCE_COOKIE);
  });
};

export const isUserLoggedIn = () => getAuthCookie();

export const isValidToken = async (callback?: () => void, onError?: () => void) => {
  const auth = getAuthCookie();
  const call = async () => {
    return axios.get(`${process.env.NEXT_PUBLIC_API_BASE_URL}/me/profile`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
  };
  try {
    if (callback) {
      await call();
      callback();
    }
  } catch {
    onError && onError();
  }
  return call;
};

const onHandleLogout = (
  callback: () => void,
  userContext: UserContextProps,
  qrEditorContext: any
) => {
  userContext?.setUser(USER_INITIAL_DATA);

  qrEditorContext?.setQrData(INITIAL_QR_DATA);
  callback();
};

export const useLogin = () => {
  const { toast } = useToast();

  const layoutContext = React.useContext(LayoutContext);

  const userContext = React.useContext(UserContext);

  const qrEditorContext = React.useContext(QREditorContext);

  const [loginData, setLoginData] = React.useState(undefined);

  const { request, loading, data, error } = useApi<PostLoginResponseData>(
    postLoginPostHandler.postLogin
  );
  const { request: requestProfile, loading: isProfileLoading } = useApi<GetProfileResponse>(
    getProfileHandler.getProfile
  );

  const onSaveCookie = (token: string, tokenExpiration: string) => {

    if (!token) return;
    setAuthCookie(token, tokenExpiration);
  };

  const onHandleProfile = async () => {
    const token = getAuthCookie();
    requestProfile({ authorization: token })
      .then((response: { data: GetProfileResponse }) => {
        const profile = response?.data;

        if (profile) {
          amplitudeTrackUser(profile?.id);
          const formattedUser = userProfileApiToDataTransformer(profile);

          userContext?.setUser({
            ...USER_INITIAL_DATA,
            ...formattedUser,
          });
        }
        layoutContext?.setIsLoading(false);
        setLoginData(data);
        return data;
      })
      .catch(() => {
        toast({ message: "genericError", type: "error" });
        layoutContext?.setIsLoading(false);
        return undefined;
      });
  };

  React.useEffect(() => {
    if (data?.status === 200) {
      const token = data?.data?.token;
      const tokenExpiration = data?.data?.expiration;

      onSaveCookie(token, tokenExpiration);
      layoutContext?.setIsLoading(false);
      setLoginData(data);
    }
  }, [data]);

  return {
    onHandleLogin: ({ email, password, reference }) =>
      onHandleLogin({ email, password, request, reference }),
    onHandleLogout: () =>
      onHandleLogout(
        () => {
          removeAuthCookie();
          removeCookie(UTMS);
        },
        userContext,
        qrEditorContext
      ),
    isValidToken: () => isValidToken(request),
    isUserLoggedIn,
    onSaveCookie,
    onHandleProfile,
    isLoginLoading: loading || isProfileLoading,
    loginResponse: loginData,
    loginError: error,
  };
};
